import { useEffect, useState } from "react";

function rad2degr(rad) {
  return (rad * 180) / Math.PI;
}
function degr2rad(degr) {
  return (degr * Math.PI) / 180;
}

export default function Helper() {
  const isMobile = function (width) {
    return width <= 768;
  };
  const screenWidth = function () {
    return window.innerWidth;
  };
  const screenHeight = function () {
    return window.innerHeight;
  };
  const useIsVisible = (ref) => {
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => {
        setIntersecting(entry.isIntersecting);
      });

      observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };
    }, [ref]);

    return isIntersecting;
  };
  const computeCentroid = (locations) => {
    if (locations.length === 0) {
      return {
        lat: -23.6830227,
        lng: -46.587224,
      };
    }

    let sumX = 0;
    let sumY = 0;
    let sumZ = 0;

    for (let i = 0; i < locations.length; i++) {
      if (locations[i].localizacao && locations[i].localizacao.includes(",")) {
        let lat = degr2rad(locations[i].localizacao.split(",")[0]);
        let lng = degr2rad(locations[i].localizacao.split(",")[1]);
        // sum of cartesian coordinates
        sumX += Math.cos(lat) * Math.cos(lng);
        sumY += Math.cos(lat) * Math.sin(lng);
        sumZ += Math.sin(lat);
      }
    }

    let avgX = sumX / locations.length;
    let avgY = sumY / locations.length;
    let avgZ = sumZ / locations.length;

    let lng = Math.atan2(avgY, avgX);
    let hyp = Math.sqrt(avgX * avgX + avgY * avgY);
    let lat = Math.atan2(avgZ, hyp);

    return {
      lat: rad2degr(lat),
      lng: rad2degr(lng),
    };
  };
  const getCityColor = (city) => {
    if (city === "Santo André") return "#ED1C24";
    if (city === "Diadema") return "#005EAD";
    if (city === "Mauá") return "#00AEEF";
    if (city === "São Caetano do Sul") return "#FAA61A";
    if (city === "Rio Grande da Serra") return "#8D64AA";
    if (city === "Ribeirão Pires") return "#EF5BA1";
    if (city === "São Bernardo do Campo") return "#0DB14B";
    if (city === "São Paulo") return "#603814";

    return "#ED1C24";
  };
  const getMapStyle = () => {
    return [
      {
        featureType: "all",
        elementType: "all",
        stylers: [
          {
            saturation: "0",
          },
          {
            color: "#c12828",
          },
          {
            visibility: "on",
          },
        ],
      },
      {
        featureType: "all",
        elementType: "geometry",
        stylers: [
          {
            color: "#2468ef",
          },
          {
            visibility: "on",
          },
        ],
      },
      {
        featureType: "all",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#cece54",
          },
          {
            visibility: "on",
          },
        ],
      },
      {
        featureType: "all",
        elementType: "geometry.stroke",
        stylers: [
          {
            color: "#ffffff",
          },
          {
            visibility: "on",
          },
        ],
      },
      {
        featureType: "all",
        elementType: "labels.text.fill",
        stylers: [
          {
            gamma: 0.01,
          },
          {
            lightness: 20,
          },
          {
            weight: "1.39",
          },
          {
            color: "#ffffff",
          },
        ],
      },
      {
        featureType: "all",
        elementType: "labels.text.stroke",
        stylers: [
          {
            weight: "0.96",
          },
          {
            saturation: "9",
          },
          {
            visibility: "on",
          },
          {
            color: "#000000",
          },
        ],
      },
      {
        featureType: "all",
        elementType: "labels.icon",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "administrative",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#674a4a",
          },
        ],
      },
      {
        featureType: "administrative.country",
        elementType: "geometry",
        stylers: [
          {
            visibility: "on",
          },
        ],
      },
      {
        featureType: "administrative.country",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#564747",
          },
        ],
      },
      {
        featureType: "landscape",
        elementType: "geometry",
        stylers: [
          {
            lightness: 30,
          },
          {
            saturation: "9",
          },
          {
            color: "#29446b",
          },
        ],
      },
      {
        featureType: "landscape",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#cece54",
          },
        ],
      },
      {
        featureType: "landscape.natural.terrain",
        elementType: "geometry",
        stylers: [
          {
            visibility: "on",
          },
          {
            color: "#f3a9a9",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "geometry",
        stylers: [
          {
            saturation: 20,
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [
          {
            lightness: 20,
          },
          {
            saturation: -20,
          },
        ],
      },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [
          {
            lightness: "-51",
          },
          {
            saturation: "57",
          },
          {
            visibility: "on",
          },
          {
            color: "#174f2b",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#adad54",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "geometry.stroke",
        stylers: [
          {
            saturation: 25,
          },
          {
            lightness: 25,
          },
          {
            weight: "0.01",
          },
          {
            visibility: "on",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "all",
        stylers: [
          {
            lightness: -20,
          },
        ],
      },
    ];
  };
  return {
    isMobile,
    screenWidth,
    screenHeight,
    useIsVisible,
    computeCentroid,
    getMapStyle,
    getCityColor,
  };
}
