import React from "react";
import styled from "styled-components";

const Element = styled.div`
  width: 100%;
  background: #dce554;
  position: relative;
  z-index: 1000000;
  padding: 50px;

  display: flex;
  align-items: start;
  justify-content: space-between;

  @media (max-width: 768px) {
    display: block;
  }
`;

const Left = styled.div`
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const Right = styled.div`
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Title = styled.div`
  color: #020202;
  padding-bottom: 12px;

  font-family: Degular;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 28px */
`;
const Logo = styled.img`
  height: 86px;

  @media (max-width: 768px) {
    margin-bottom: 42px;
  }
`;
const Content = styled.div`
  color: #020202;

  font-family: Degular;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 28px */

  strong {
    font-weight: 700;
  }

  @media (max-width: 768px) {
  }
`;

export default function About({ slug }) {
  return (
    <Element>
      <Left>
        <Title>Sobre o projeto</Title>
        {slug !== "abc-paulista" && slug !== "sao-paulo" && (
          <Logo src="/img/bonde.svg" />
        )}
      </Left>
      <Right>
        {slug !== "abc-paulista" && slug !== "sao-paulo" && (
          <Content>
            Este mapa faz parte do <strong>Projeto Bonde LGBT+</strong>, uma
            iniciativa do VoteLGBT em parceria com organizações locais de cada
            um dos territórios mapeados.
            <br />
            <br />
            Nosso objetivo é aumentar a representatividade LGBT+ em todos os
            espaços da sociedade, desenvolvendo o ecossistema LGBT+ local, nos
            permitindo ocupar todos os espaços de poder, inclusive nossos
            cotidianos.
          </Content>
        )}
        {slug === "abc-paulista" && (
          <Content>
            Este guia faz parte do projeto <strong>Trajeto Afeto</strong>, do{" "}
            <strong>Sesc Santo André</strong> em parceria com o{" "}
            <strong>VoteLGBT</strong>. O projeto parte de um mapeamento de
            espaços na região do ABC voltados para a população LGBT+.
          </Content>
        )}
        {slug === "sao-paulo" && (
          <Content>
            Este mapa é uma iniciativa do <strong>VoteLGBT</strong> com o
            objetivo de aumentar a representatividade LGBT+ em todos os espaços
            da sociedade, desenvolvendo o ecossistema LGBT+ paulistano, nos
            permitindo ocupar todos os espaços de poder, inclusive nossos
            cotidianos.
          </Content>
        )}
      </Right>
    </Element>
  );
}
