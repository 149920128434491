import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import NewFooter from "./components/footer";

// libs
import Helper from "../libs/helper";

// elements
// import Header from "./components/header";
import Content from "./components/content";
import Missing from "./components/missing";
import GoogleMaps from "./components/map";
import Display from "./components/display";
import Api from "../libs/api";
import About from "./components/about";

const Holder = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background: #dce554;
  overflow-x: hidden;
`;

const Clear = styled.div`
  clear: both;
`;

// const HeaderBG = styled.div`
//   background: #002ac3;
// `;

export default function Map() {
  // instances
  const helper = Helper();
  const api = Api();

  const { slug } = useParams();
  // visible detection
  const _content = useRef();
  const isVisible = helper.useIsVisible(_content);

  // states
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);
  const [map, setMap] = useState();

  function handleWindowSizeChange() {
    setHeight(window.innerHeight);
    setWidth(window.innerWidth);
  }

  const getData = async () => {
    const response = await api.getMap(slug);
    setMap(response);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    window.addEventListener("load", handleWindowSizeChange);
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Holder id={"main"}>
        <div ref={_content} style={{ background: "rgb(220, 229, 84)" }}>
          {map && (
            <Content width={width}>
              <Display
                title={map.nome}
                image={map.cover}
                imageMobile={map.cover_mobile}
                intro={map.texto}
                isMobile={helper.isMobile(width)}
                height={height}
                logo1={map.logo_1}
                logo2={map.logo_2}
                logo3={map.logo_3}
              />
            </Content>
          )}
        </div>
        <div id="divisor" />
        {map && (
          <GoogleMaps
            file={[`${slug}`]}
            width={width}
            height={height}
            isMobile={helper.isMobile(width)}
            isFloater={!isVisible}
            id={"main"}
            map={map}
          />
        )}
        <Clear />
        <Missing isMobile={helper.isMobile(width)} />
        <About slug={slug} />
        <NewFooter />
      </Holder>
    </>
  );
}
