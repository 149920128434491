import React, { useState, useEffect } from "react";
import styled from "styled-components";

// import Helper from "../../libs/helper";

const Share = styled.div`
  position: absolute;
  right: 16px;
  width: 15px;
  justify-content: center;
  bottom: 0px;
  display: flex;
  cursor: pointer;
  height: 32px;
  align-items: center;
`;

const ShareElement = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 8px 0px;
  position: absolute;
  right: -16px;
  top: 25px;
  z-index: 20;
  width: 163px;
`;

const ShareImg = styled.img`
  &:hover {
    opacity: 0.8;
  }
`;

const ShareItem = styled.a`
  display: block;

  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  color: #000000;
  padding: 16px;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;

export default function ShareBox({ last, marker, isMobile, callback }) {
  //   let helper = Helper();

  const [open, setOpen] = useState(false);

  document.body.addEventListener(
    "click",
    (event) => {
      if (!event.target.classList.contains("shareBox")) {
        setOpen(false);
      }
    },
    true
  );

  const getGoogleLinkDir = (marker) => {
    return marker.link;
  };

  const getWhatsappLink = (marker) => {
    return isMobile
      ? `whatsapp://send?text==${marker.nome} ${getGoogleLinkDir(marker)}`
      : `https://wa.me/send?text=${marker.nome} ${getGoogleLinkDir(marker)}`;
  };

  const getWhatsappLinkSocial = (name, username) => {
    return isMobile
      ? `whatsapp://send?text==${marker.nome} https://${name}/${username}`
      : `https://wa.me/send?text=${marker.nome} https://${name}/${username}`;
  };

  useEffect(() => {
    if (callback) {
      callback(open);
    }

    if (isMobile && open) {
      document.body.style.overflow = "hidden";
    }
    if (!open) {
      document.body.style.overflow = "auto";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Share
      className="shareBox"
      onClick={() => {
        setOpen(!open);
      }}
    >
      <ShareImg src="/images/dots.svg" alt="Share" />
      {open && (
        <ShareElement
          style={{
            top: last ? "auto" : isMobile ? "auto" : "25px",
            bottom: last ? "25px" : isMobile ? "0px" : "auto",
            left: isMobile ? "0px" : "auto",
            position: isMobile ? "fixed" : "absolute",
            width: isMobile ? "100%" : "183px",
            zIndex: isMobile ? 999990 : 20,
          }}
        >
          {marker.link.length > 0 && (
            <ShareItem
              target="_blank"
              href={`${getWhatsappLink(marker)}`}
              rel="noreferrer"
            >
              Compartilhar
            </ShareItem>
          )}
          {marker.instagram ? (
            <ShareItem
              target="_blank"
              href={`${getWhatsappLinkSocial("instagram", marker.instagram)}`}
              rel="noreferrer"
            >
              Compartilhar
            </ShareItem>
          ) : marker.facebook ? (
            <ShareItem
              target="_blank"
              href={`${getWhatsappLinkSocial("facebook", marker.facebook)}`}
              rel="noreferrer"
            >
              Compartilhar
            </ShareItem>
          ) : (
            <></>
          )}
          {marker.link.length > 0 && (
            <ShareItem
              target="_blank"
              href={getGoogleLinkDir(marker)}
              rel="noreferrer"
            >
              Abrir no Google Maps
            </ShareItem>
          )}
        </ShareElement>
      )}
    </Share>
  );
}
