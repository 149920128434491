import React, { useState, useEffect } from "react";
import styled from "styled-components";

// import Helper from "../../libs/helper";

import ShareBox from "./share";

const Element = styled.div`
  background: #dce554;
  width: 100%;
  padding: 24px 32px;
  overflow: scroll;
`;

const Card = styled.div`
  width: 100%;
  background: #ffffff;
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 16px 16px 16px 12px;
  margin-bottom: 16px;
  border-left: 4px solid #ffffff;
  position: relative;
  cursor: pointer;
  /* &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.45);
  } */
`;

const Tag = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 125%;
  color: #000000;
  padding: 5px 8px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  float: left;
  margin-right: 8px;
`;

const Clear = styled.div`
  clear: both;
`;

const Name = styled.div`
  font-style: 700;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  text-transform: uppercase;
  color: #ed1c24;
  margin-top: 16px;
`;

const Address = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #000000;
  margin-top: 8px;
`;

const City = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  color: #000000;
`;

// const Social = styled.div`
//   margin-top: 8px;
// `;

const SocialLink = styled.a`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  text-decoration-line: underline;
  display: block;
  color: #000000;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 80%;
  &:hover {
    color: rgba(0, 0, 0, 0.7);
  }
`;

const Search = styled.input`
  border: none;
  background: #ffffff;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 24px;
  padding: 13px;
  padding-left: 46px;
  background-image: url(/images/search.svg);
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: 12px 12px;
`;

export default function ListView({
  width,
  markers,
  callback,
  mapIndex,
  isMobile,
  openCallback,
  getMarkers,
}) {
  // let helper = Helper();

  const [selected, setSelected] = useState(null);

  useEffect(() => {
    setSelected(mapIndex);
    setTimeout(() => {
      const element = document.getElementById(`card_${mapIndex}`);
      if (element) {
        element.scrollIntoView({ behavior: "auto", block: "nearest" });
      }
    }, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapIndex]);

  const [search, setSearch] = useState();

  useEffect(() => {
    getMarkers(0, search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (isMobile) {
      window.scrollTo(0, window.innerHeight + 50);
    } else {
      let element = document.getElementById(`marker_list`);
      element.scrollTo(0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markers]);

  return (
    <Element
      id={"marker_list"}
      style={{
        width: width,
        height: isMobile ? "auto" : "100%",
        padding: isMobile ? "24px 16px" : "24px 32px",
      }}
    >
      {" "}
      <Search
        placeholder="Buscar"
        on
        onChange={(event) => setSearch(event.target.value)}
      />
      {markers.map((marker, index) => {
        // console.log(index);
        return (
          <Card
            key={index}
            id={`card_${index}`}
            style={{
              borderLeftColor:
                selected === index && !isMobile ? "#D7729E" : "#ffffff",
              zIndex: 100000 - index,
            }}
            onClick={() => {
              setSelected(index);
              if (marker.localizacao.length > 0) {
                callback(index);
              } else {
                callback(null);
              }
            }}
          >
            <Tag>{marker.categoria.nome}</Tag>
            <Clear />
            <Name style={{ color: "#D7729E" }}>{marker.nome}</Name>
            <Address>{marker.endereco}</Address>
            <City>{marker.cidade}</City>
            {marker.facebook && (
              <SocialLink key={0} href={`${marker.facebook}`} target="_blank">
                {marker.facebook}
              </SocialLink>
            )}
            {marker.instagram && (
              <SocialLink key={0} href={`${marker.instagram}`} target="_blank">
                {marker.instagram}
              </SocialLink>
            )}
            {((marker.localizacao && marker.localizacao.length > 0) ||
              marker.facebook ||
              marker.instsagram) && (
              <ShareBox
                last={markers.length - 1 === index ? true : false}
                marker={marker}
                isMobile={isMobile}
                callback={(open) => {
                  openCallback(open);
                }}
              />
            )}
          </Card>
        );
      })}
    </Element>
  );
}
