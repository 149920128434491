import React from "react";
import styled from "styled-components";

const Element = styled.div`
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 99999;
  background: rgb(220, 229, 84);
  padding: 0px 25px;

  @media (max-width: 768px) {
    padding: 0px 0px;
  }
`;

export default function Content({ children, width }) {
  return <Element>{children}</Element>;
}
