import React from "react";
import styled from "styled-components";

const Footer = styled.div`
  padding: 50px;
  display: flex;
  align-items: start;
  justify-content: space-between;
  background: #fbf7f4;

  @media (max-width: 768px) {
    padding: 50px 16px;
    display: block;
  }
`;

const FooterContent = styled.div``;

const FooterMenu = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;

  padding-bottom: 24px;

  @media (max-width: 768px) {
    display: block;
  }
`;

const FooterMenuLink = styled.a`
  display: block;
  margin-right: 5px;
  margin-left: 5px;
  margin-right: 20px;
  color: #da709f;

  font-family: Degular;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 28px */
  text-decoration: underline;

  @media (max-width: 768px) {
    float: left;
  }
`;

const Social = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    justify-content: center;
  }
`;
const SocialLink = styled.div`
  display: block;
  margin-right: 5px;
  margin-left: 5px;
`;

const FooterText = styled.div`
  width: 100%;
  padding-bottom: 24px;
`;

const Clear = styled.div`
  clear: both;
`;

export default function NewFooter() {
  return (
    <Footer>
      <FooterContent>
        <FooterMenu>
          <FooterMenuLink href="https://www.votelgbt.org/sobre" target="_blank">
            Sobre
          </FooterMenuLink>
          <FooterMenuLink
            href="https://www.votelgbt.org/contato"
            target="_blank"
          >
            Contato
          </FooterMenuLink>
          <FooterMenuLink
            href="https://www.votelgbt.org/imprensa"
            target="_blank"
          >
            Imprensa
          </FooterMenuLink>
          <FooterMenuLink href="https://linktr.ee/votelgbt" target="_blank">
            Linktree
          </FooterMenuLink>
          <FooterMenuLink href="https://www.votelgbt.org/termo" target="_blank">
            Termos de uso
          </FooterMenuLink>
          <Clear />
        </FooterMenu>
        <FooterText>© 2023, VoteLGBT. All rights reserved.</FooterText>
        <FooterMenu>
          <FooterMenuLink href="https://fehra.co" target="_blank">
            Estúdio Fehra
          </FooterMenuLink>
          <Clear />
        </FooterMenu>
      </FooterContent>
      <Social>
        <SocialLink href="https://www.instagram.com/votelgbt/" target="_blank">
          <img src="/img/instagram.png" width={20} alt="Instagram" />
        </SocialLink>
        <SocialLink href="https://twitter.com/votelgbt" target="_blank">
          <img src="/img/twitter.png" width={20} alt="Twitter" />
        </SocialLink>
        <SocialLink href="https://www.facebook.com/votelgbt" target="_blank">
          <img src="/img/facebook.png" width={20} alt="Facebook" />
        </SocialLink>
      </Social>
    </Footer>
  );
}
