import React from "react";
import styled from "styled-components";
// import { Parallax } from "react-parallax";

const Element = styled.div`
  width: 100%;
  padding: 96px 24px;
  position: relative;
  z-index: 2;
  background: #dce554;
  display: flex;
  align-items: start;
  justify-content: space-between;

  @media (max-width: 768px) {
    width: 100%;
    display: block;
  }
`;

const Text = styled.div`
  color: #020202;
  font-family: Degular;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  width: 50%;
  /* padding-left: 50px; */
  white-space: pre-wrap;

  @media (max-width: 768px) {
    width: 100%;
    padding-left: 0px;
    padding-top: 24px;
  }

  img {
    height: 50px;
    margin-right: 32px;
  }
`;

const Title = styled.div`
  color: #d7729e;
  font-family: Ohno Blazeface;
  font-size: 24px;
  font-style: normal;
  font-weight: 100;
  line-height: 100%; /* 24px */
  width: 100%;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const Subtitle = styled.div`
  color: #d7729e;

  font-family: Ohno Blazeface;
  font-size: 76px;
  font-style: normal;
  font-weight: 300;
  line-height: 100%; /* 76px */
  width: 100%;

  @media (max-width: 768px) {
    font-size: 44px;
  }
`;

const Clear = styled.div`
  clear: both;
`;

export default function Display({
  title,
  image,
  imageMobile,
  intro,
  isMobile,
  height,
  logo1,
  logo2,
  logo3,
}) {
  return (
    <Element
      style={{
        padding: isMobile ? "32px 24px" : "96px 24px",
      }}
    >
      <div>
        <Title>Mapa LGBT+</Title>
        <Clear />
        <Subtitle>{title}</Subtitle>
      </div>
      <Text>
        {intro} <br />
        <br />
        {(logo1 || logo2 || logo3) && (
          <>
            Realização
            <br />
            <br />
          </>
        )}
        {logo1 && <img src={logo1} alt="Logo" />}
        {logo2 && <img src={logo2} alt="Logo" />}
        {logo3 && <img src={logo3} alt="Logo" />}
      </Text>
    </Element>
  );
}
