import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Map from "google-map-react";

import Helper from "../../libs/helper";

import Marker from "./marker";
import ListView from "./list_view";
import ShareBox from "./share";

const Element = styled.div``;

const ListHolder = styled.div`
  float: left;
  width: 25%;
`;

const MapHolder = styled.div`
  float: left;
  z-index: 1;
  width: 75%;
`;

const Clear = styled.div`
  clear: both;
`;

const ChangeMode = styled.div`
  position: fixed;
  left: 50%;
  margin-left: -21px;
  bottom: 16px;
  width: 42px;
  height: 42px;
  cursor: pointer;
  /* -webkit-transform: translateZ(0); */
  z-index: 999999;
  transition: opacity 1s;
`;

const SingleCard = styled.div`
  position: absolute;
  top: 26px;
  left: 16px;
  right: 16px;
  height: auto;
  z-index: 1000000;
`;

const Card = styled.div`
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 16px 16px 16px 12px;
  margin-bottom: 16px;
  border-left: 4px solid #ffffff;
  position: relative;
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.45);
  }
`;

const Tag = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 125%;
  color: #000000;
  padding: 5px 8px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  float: left;
  margin-right: 8px;
`;

const Name = styled.div`
  font-style: 700;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  text-transform: uppercase;
  color: #ed1c24;
  margin-top: 16px;
`;

const Address = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #000000;
  margin-top: 8px;
`;

// const City = styled.div`
//   font-style: normal;
//   font-weight: 700;
//   font-size: 12px;
//   line-height: 140%;
//   color: #000000;
// `;

const Social = styled.div`
  margin-top: 8px;
`;

const SocialLink = styled.a`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  text-decoration-line: underline;
  display: block;
  color: #000000;
  &:hover {
    color: rgba(0, 0, 0, 0.7);
  }
`;

const Close = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 11px;
  top: 11px;
  cursor: pointer;
`;

export default function MapView({
  markers,
  width,
  height,
  isMobile,
  customCenter,
  callback,
  getMarkers,
}) {
  let helper = Helper();
  const [selected, setSelected] = useState(null);
  const [marker, setMarker] = useState(null);
  const [showMap, setShowMap] = useState(isMobile ? true : true);
  const [showList, setShowList] = useState(isMobile ? false : true);
  const [openShare, setOpenShare] = useState(false);

  useEffect(() => {
    setMarker(markers[selected]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    setSelected(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markers]);

  let center = helper.computeCentroid(markers);

  // if (customCenter) {
  //   center = customCenter;
  // }

  const [opacity, setOpacity] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position > 300) {
      setOpacity(1);
    } else {
      setOpacity(0);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Element>
      {isMobile && showMap && marker && (
        <SingleCard>
          <Card>
            <Close
              onClick={() => {
                setSelected(null);
                callback(null);
              }}
            >
              <img src="/images/close.svg" alt="Fechar" />
            </Close>
            <Tag>{marker.categoria.nome}</Tag>
            <Clear />
            <Name style={{ color: helper.getCityColor(marker.cidade) }}>
              {marker.nome}
            </Name>
            <Address>{marker.endereco}</Address>
            {/* <City>{marker.cidade}</City> */}
            <Social>
              {marker.facebook && (
                <SocialLink key={0} href={`${marker.facebook}`} target="_blank">
                  {marker.facebook}
                </SocialLink>
              )}
              {marker.instagram && (
                <SocialLink
                  key={0}
                  href={`${marker.instagram}`}
                  target="_blank"
                >
                  {marker.instagram}
                </SocialLink>
              )}
            </Social>

            {(marker.localizacao.length > 0 || marker.socials.length > 0) && (
              <ShareBox last={false} marker={marker} isMobile={isMobile} />
            )}
          </Card>
        </SingleCard>
      )}
      {showList && (
        <ListHolder
          style={{
            width: isMobile ? width : "25%",
            height: isMobile ? "auto" : height - 100,
          }}
        >
          <ListView
            isMobile={isMobile}
            markers={markers}
            mapIndex={selected}
            callback={(index) => {
              setSelected(index);
              callback(index);
            }}
            openCallback={(open) => {
              // console.log(open);
              setOpenShare(open);
            }}
            getMarkers={getMarkers}
          />
        </ListHolder>
      )}
      {showMap && (
        <MapHolder
          style={{
            width: isMobile ? width : "75%",
            height: height - 100,
          }}
        >
          <Map
            bootstrapURLKeys={{
              key: "AIzaSyDwvV0QJfCrPVuitkgsedBvZPpT_wu5Qw4",
            }}
            yesIWantToUseGoogleMapApiInternals
            defaultZoom={12}
            options={{
              styles: helper.getMapStyle(),
              fullscreenControl: false,
              scrollwheel: false,
            }}
            center={[center.lat, center.lng]}
          >
            {markers.map((marker, index) => {
              // console.log(index);
              return (
                marker.localizacao &&
                marker.localizacao.length > 0 && (
                  <Marker
                    key={index}
                    lat={parseFloat(marker.localizacao.split(",")[0])}
                    lng={parseFloat(marker.localizacao.split(",")[1])}
                    index={index}
                    marker={marker}
                    selected={selected === index ? true : false}
                    callback={(index) => {
                      setSelected(index);
                      callback(index);
                    }}
                  />
                )
              );
            })}
          </Map>
        </MapHolder>
      )}
      {isMobile && !openShare && (
        <ChangeMode style={{ opacity: opacity }}>
          <img
            src="/images/show_list.svg"
            alt="Mostrar Mapa"
            style={{
              opacity: 1, //showMap ? 1 : 0,
              zIndex: showMap ? 1 : 0,
              position: "absolute",
              transition: "opacity 1s",
            }}
            onClick={() => {
              const element = document.getElementById(`divisor`);
              if (element) {
                element.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }
              setSelected(null);
              callback(null);
              setShowList(true);
              setShowMap(false);
            }}
          />
          <img
            src="/images/show_map.svg"
            alt="Mostrar Mapa"
            style={{
              opacity: 1, //showList ? 1 : 0,
              zIndex: showList ? 1 : 0,
              position: "absolute",
              transition: "opacity 1s",
            }}
            onClick={() => {
              const element = document.getElementById(`divisor`);
              if (element) {
                element.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }
              setSelected(null);
              callback(null);
              setShowList(false);
              setShowMap(true);
            }}
          />
          {/* {showMap ? (
            
          ) : (
            
          )} */}
        </ChangeMode>
      )}
      <Clear />
    </Element>
  );
}
