import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Api from "../libs/api";

import NewFooter from "./components/footer";

const Holder = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background: #dce554;
  overflow-x: hidden;
  padding-bottom: 74px;
`;

const Header = styled.div`
  width: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-image: url(/img/estados.svg);
  background-position: center center;
  background-repeat: no-repeat;

  @media (max-width: 768px) {
    background-image: none;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Content = styled.div`
  color: #da709f;

  text-align: center;
  font-family: Degular;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 28px */
  max-width: 728px;
  padding-top: 22px;

  @media (max-width: 768px) {
    padding: 16px;
    color: #da709f;

    text-align: center;
    font-family: Degular;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 28px */
  }
`;

const Menu = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: 40px 50px;

  @media (max-width: 768px) {
    padding: 16px;
  }

  @media (max-width: 768px) {
    display: block;
  }
`;

const MenuText = styled.div`
  color: #da709f;

  font-family: Degular;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 28px */
  width: 40%;
  padding-right: 40px;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0px 0px 16px 0px;
  }
`;

const MenuHolder = styled.div`
  width: 60%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const MenuLine = styled.div`
  border-top: 1px solid #d7729e;
  width: 100%;
`;

const MenuItem = styled.a`
  color: #d7729e;

  font-family: Ohno Blazeface;
  font-size: 44px;
  font-style: normal;
  font-weight: 100;
  line-height: 181%; /* 79.64px */
  display: block;

  background: url(/img/arrow_right.svg) right center no-repeat;
  background-size: 30px;

  @media (max-width: 768px) {
    font-size: 32px;
    background-size: 25px;
  }
`;

export default function Home() {
  const api = Api();

  // states
  const [height, setHeight] = useState(window.innerHeight);
  const [maps, setMaps] = useState([]);

  function handleWindowSizeChange() {
    setHeight(window.innerHeight);
  }

  const getData = async () => {
    const response = await api.getMaps();
    setMaps(response);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    window.addEventListener("load", handleWindowSizeChange);
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Holder id={"main"}>
        <Header style={{ height: height }}>
          <img src="/img/logo.svg" alt="Logo" />
          <Content>
            Conheça lugares onde você pode se sentir livre para trocar ideia,
            paquerar, cuidar da saúde, manifestar sua fé, se reunir com grupos
            ativistas, entre várias outras possibilidades. Vem descobrir cidades
            + LGBT+ com a gente!
          </Content>
        </Header>
        <Menu>
          <MenuText>
            Veja aqui os mapeamentos <br />
            feitos até o momento:
          </MenuText>
          <MenuHolder>
            {maps.map((map) => {
              return (
                <>
                  <MenuLine />
                  <MenuItem href={`/${map.slug}`}>{map.nome}</MenuItem>
                </>
              );
            })}
            <MenuLine />
          </MenuHolder>
        </Menu>
      </Holder>
      <NewFooter />
    </>
  );
}
