import React from "react";
import styled from "styled-components";

const Item = styled.a`
  width: 150px;
  padding: 16px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #000;

  text-align: center;
  font-family: Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 12.5px */
  opacity: 0.5;
  float: left;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
  &.active {
    opacity: 1 !important;
    font-weight: 700;
  }
  &.noHover {
    opacity: 0.5;
  }
`;

const ItemIcon = styled.div`
  margin-bottom: 13px;
`;

export default function Icon({
  slug,
  selected,
  setSelected,
  setCategory,
  text,
  width,
  isMobile,
}) {
  const itemWidthMobile = width / 4 - width / 4 / 4 / 2;

  const _scroll = (slug) => {
    const element = document.getElementById(`item_${slug}`);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "center",
      });
    }
  };

  return (
    <>
      {slug === "todos" && (
        <Item
          id={`item_${slug}`}
          onClick={() => {
            setSelected(text);
            setCategory(slug);
            _scroll(slug);
          }}
          className={`${selected ? "active" : ""} ${isMobile ? "noHover" : ""}`}
          style={{
            color: selected ? "#D7729E" : "#000000",
            width: isMobile ? itemWidthMobile : 150,
          }}
        >
          <ItemIcon>
            <svg width="24" height="24" viewBox="0 0 24 24">
              <path
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                d="m8.33,4.76V1h14.67v14.67h-3.76"
              />
              <path
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                d="m4.58,8.33v-3.58h14.67v14.67h-3.58"
              />
              <path
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                d="m15.67,8.33H1v14.67h14.67v-14.67Z"
              />
            </svg>
          </ItemIcon>
          Todos
        </Item>
      )}
      {slug === "assistencia" && (
        <Item
          id={`item_${slug}`}
          onClick={() => {
            setSelected(text);
            setCategory(slug);
            _scroll(slug);
          }}
          className={`${selected ? "active" : ""} ${isMobile ? "noHover" : ""}`}
          style={{
            color: selected ? "#D7729E" : "#000000",
            width: isMobile ? itemWidthMobile : 150,
          }}
        >
          <ItemIcon>
            <svg width="24" height="24" viewBox="0 0 24 24">
              <line
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                x1="1"
                y1="19.86"
                x2="4.14"
                y2="23"
              />
              <line
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                x1="1.79"
                y1="20.64"
                x2="4.93"
                y2="17.5"
              />
              <line
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                x1="18.29"
                y1="5.71"
                x2="23"
                y2="1"
              />
              <line
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                x1="3.36"
                y1="22.21"
                x2="6.5"
                y2="19.07"
              />
              <rect
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                x="8.99"
                y="5.01"
                width="4.44"
                height="15.56"
                transform="translate(12.33 -4.18) rotate(45)"
              />
              <rect
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                x="16.39"
                y="5.39"
                width="2.22"
                height="2.22"
                transform="translate(9.72 -10.47) rotate(45)"
              />
              <line
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                x1="12.79"
                y1="8.07"
                x2="14.36"
                y2="9.64"
              />
              <line
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                x1="6.5"
                y1="14.36"
                x2="8.07"
                y2="15.93"
              />
              <line
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                x1="9.64"
                y1="11.21"
                x2="10.43"
                y2="12"
              />
              <polyline
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                points="12 8.86 6.5 3.36 3.36 6.5 8.86 12"
              />
              <polyline
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                points="12 15.14 19.86 23 23 19.86 15.37 12.23"
              />
              <polyline
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                points="3.36 6.5 1 1 6.5 3.36"
              />
              <polygon points="2.18 3.75 3.75 2.18 1 1 2.18 3.75" />
              <line
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                x1="17.5"
                y1="20.64"
                x2="20.64"
                y2="17.5"
              />
            </svg>
          </ItemIcon>
          {text}
        </Item>
      )}
      {slug === "politica" && (
        <Item
          id={`item_${slug}`}
          onClick={() => {
            setSelected(text);
            setCategory(slug);
            _scroll(slug);
          }}
          className={`${selected ? "active" : ""} ${isMobile ? "noHover" : ""}`}
          style={{
            color: selected ? "#D7729E" : "#000000",
            width: isMobile ? itemWidthMobile : 150,
          }}
        >
          <ItemIcon>
            <svg width="24" height="24" viewBox="0 0 24 24">
              <polygon
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                points="5.21 16.4 3.1 17.18 1 11.37 3.11 10.59 5.21 16.4"
              />
              <polyline
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                points="14.57 14.6 16.96 21.19 12.11 23 9.26 15.13"
              />
              <polygon
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                points="23 14.14 20.89 14.93 16.13 1.79 18.24 1 23 14.14"
              />
              <line
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                x1="3.61"
                y1="11.96"
                x2="16.14"
                y2="3.47"
              />
              <line
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                x1="4.86"
                y1="15.42"
                x2="20.2"
                y2="13.78"
              />
            </svg>
          </ItemIcon>
          {text}
        </Item>
      )}
      {slug === "fervo" && (
        <Item
          id={`item_${slug}`}
          onClick={() => {
            setSelected(text);
            setCategory(slug);
            _scroll(slug);
          }}
          className={`${selected ? "active" : ""} ${isMobile ? "noHover" : ""}`}
          style={{
            color: selected ? "#D7729E" : "#000000",
            width: isMobile ? itemWidthMobile : 150,
          }}
        >
          <ItemIcon>
            <svg width="24" height="24" viewBox="0 0 24 24">
              <polygon
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                points="1 4.14 23 4.14 11.7 13.99 1 4.14"
              />
              <line
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                x1="6.15"
                y1="23"
                x2="17.85"
                y2="23"
              />
              <line
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                x1="10.86"
                y1="10.51"
                x2="22.06"
                y2="1"
              />
              <line
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                x1="5.81"
                y1="8.33"
                x2="18.16"
                y2="8.33"
              />
              <line
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                x1="11.7"
                y1="13.99"
                x2="11.7"
                y2="23"
              />
            </svg>
          </ItemIcon>
          {text}
        </Item>
      )}
      {slug === "sociabilidade" && (
        <Item
          id={`item_${slug}`}
          onClick={() => {
            setSelected(text);
            setCategory(slug);
            _scroll(slug);
          }}
          className={`${selected ? "active" : ""} ${isMobile ? "noHover" : ""}`}
          style={{
            color: selected ? "#D7729E" : "#000000",
            width: isMobile ? itemWidthMobile : 150,
          }}
        >
          <ItemIcon>
            <svg width="24" height="24" viewBox="0 0 24 24">
              <path
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                d="m12,23c-2.01,0-3.99-.56-5.68-1.6l-.19-.1-4.06,1.16c-.15.05-.31,0-.41-.1-.12-.1-.17-.27-.12-.41l1.18-4.04-.1-.17c-1.06-1.72-1.62-3.7-1.62-5.74C1,5.94,5.93,1,12,1s11,4.94,11,11.01c-.02,6.05-4.96,10.99-11,10.99h0Z"
              />
              <circle
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                cx="8.75"
                cy="7.65"
                r="1.63"
              />
              <circle
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                cx="16.33"
                cy="10.9"
                r="1.63"
              />
              <circle
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                cx="12"
                cy="17.41"
                r="1.63"
              />
              <line
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                x1="10.06"
                y1="8.61"
                x2="14.71"
                y2="10.46"
              />
              <line
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                x1="15.61"
                y1="12.53"
                x2="12.85"
                y2="16.03"
              />
            </svg>
          </ItemIcon>
          {text}
        </Item>
      )}
      {slug === "cultura" && (
        <Item
          id={`item_${slug}`}
          onClick={() => {
            setSelected(text);
            setCategory(slug);
            _scroll(slug);
          }}
          className={`${selected ? "active" : ""} ${isMobile ? "noHover" : ""}`}
          style={{
            color: selected ? "#D7729E" : "#000000",
            width: isMobile ? itemWidthMobile : 150,
          }}
        >
          <ItemIcon>
            <svg width="24" height="24" viewBox="0 0 24 24">
              <path
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                d="m21.21,22.81c-.95,0-1.53-14.81-1.53-14.81h2.95s-.47,14.81-1.42,14.81Z"
              />
              <line
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                x1="20.09"
                y1="11"
                x2="22.1"
                y2="11"
              />
              <path
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                d="m21.31,1s2.86,2.54,1.13,4.1c-1.09.99-3.35.46-2.77-1.25s1.59-1.03,1.63-2.85Z"
              />
              <ellipse
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                cx="9.04"
                cy="6.5"
                rx="1.51"
                ry="1.5"
              />
              <ellipse
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                cx="5.02"
                cy="10.5"
                rx="1.51"
                ry="1.5"
              />
              <ellipse
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                cx="6.02"
                cy="16.5"
                rx="1.51"
                ry="1.5"
              />
              <ellipse
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                cx="11.05"
                cy="19.5"
                rx="1.51"
                ry="1.5"
              />
              <path
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                d="m20.21,18.5c-1.91,2.72-5.07,4.5-8.66,4.5-5.83,0-10.55-4.7-10.55-10.5S5.72,2,11.55,2c4.02,0,0,8,8.03,7.54"
              />
            </svg>
          </ItemIcon>
          {text}
        </Item>
      )}
      {slug === "atividade_fisica" && (
        <Item
          id={`item_${slug}`}
          onClick={() => {
            setSelected(text);
            setCategory(slug);
            _scroll(slug);
          }}
          className={`${selected ? "active" : ""} ${isMobile ? "noHover" : ""}`}
          style={{
            color: selected ? "#D7729E" : "#000000",
            width: isMobile ? itemWidthMobile : 150,
          }}
        >
          <ItemIcon>
            <svg width="24" height="24" viewBox="0 0 24 24">
              <rect
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                x="4.95"
                y="11.33"
                width="3.86"
                height="11.58"
                rx="1.93"
                ry="1.93"
                transform="translate(-10.09 9.88) rotate(-45)"
              />
              <rect
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                x="3.05"
                y="15.16"
                width="2.9"
                height="8.69"
                rx="1.45"
                ry="1.45"
                transform="translate(-12.48 8.89) rotate(-45)"
              />
              <rect
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                x="15.19"
                y="1.09"
                width="3.86"
                height="11.58"
                rx="1.93"
                ry="1.93"
                transform="translate(.15 14.12) rotate(-45)"
              />
              <rect
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                x="18.06"
                y=".15"
                width="2.9"
                height="8.69"
                rx="1.45"
                ry="1.45"
                transform="translate(2.54 15.11) rotate(-45)"
              />
              <line
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                x1="7.22"
                y1="14.73"
                x2="14.73"
                y2="7.22"
              />
              <line
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                x1="9.27"
                y1="16.78"
                x2="16.78"
                y2="9.27"
              />
            </svg>
          </ItemIcon>
          {text}
        </Item>
      )}
      {slug === "religiao" && (
        <Item
          id={`item_${slug}`}
          onClick={() => {
            setSelected(text);
            setCategory(slug);
            _scroll(slug);
          }}
          className={`${selected ? "active" : ""} ${isMobile ? "noHover" : ""}`}
          style={{
            color: selected ? "#D7729E" : "#000000",
            width: isMobile ? itemWidthMobile : 150,
          }}
        >
          <ItemIcon>
            <svg width="24" height="24" viewBox="0 0 24 24">
              <path
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                d="m3.47,17.91l1.96-1.82v-4.37L9.8,1.67c.22-.54.85-.81,1.4-.59h0c.55.22.82.84.6,1.38l-1.27,7.94"
              />
              <path
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                d="m20.53,17.91l-1.96-1.82v-4.37S14.2,1.67,14.2,1.67c-.22-.54-.85-.81-1.4-.59h0c-.55.22-.82.84-.6,1.38l1.27,7.94"
              />
              <path
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                d="m15.92,15.64l-.76-4.45c-.1-.58-.66-.97-1.24-.87l-1.03.17c-.58.1-.98.65-.88,1.22"
              />
              <path
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                d="m11.99,17.68v-5.96c.11-.58-.29-1.13-.87-1.22l-1.03-.17c-.58-.1-1.14.29-1.24.87l-.76,4.45"
              />
              <path
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                d="m1,18.8l1.46-1.55,6.09,5.75s3.44-3.19,3.44-5.32"
              />
              <path
                style={{
                  fill: "none",
                  stroke: selected ? "#D7729E" : "#000000",
                  strokeLinecap: "round",
                  strokeLineJoin: "round",
                }}
                d="m23,18.8l-1.46-1.55-6.09,5.75s-3.44-3.19-3.44-5.32"
              />
            </svg>
          </ItemIcon>
          {text}
        </Item>
      )}
    </>
  );
}
