import { Routes, Route } from "react-router-dom";
import React from "react";

import "../src/styles/style.css";

// import HomeABC from "./pages/home.abc.js";
// import HomeSP from "./pages/home.sp.js";

import Home from "./pages/home.js";
import Map from "./pages/map.js";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/:slug" element={<Map />} />
      {/* <Route path="/sp" element={<HomeSP />} />
      <Route path="/abc" element={<HomeABC />} /> */}
    </Routes>
  );
}

export default App;
