import React, { useState } from "react";
import styled from "styled-components";

import Icon from "./icon";

const Element = styled.div`
  height: 100px;
  width: 100%;
  position: relative;
  z-index: 99999;
`;

const Scroller = styled.div`
  overflow: auto;
  height: 100px;
  position: relative;
  left: 0px;
  top: 0px;
  z-index: 99;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-top: 1px;
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: #dce554;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Items = styled.div`
  margin: 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function Menu({
  isMobile,
  width,
  setCategory,
  isFloater,
  categories,
}) {
  // console.log(categories);

  const [selected, setSelected] = useState(null);

  const itemWidthMobile = width / 4 - width / 4 / 4 / 2;

  return (
    <Element>
      <Scroller
        style={{
          // background: isFloater ? "#fbf7f4" : "#ffffff",
          // boxShadow: isFloater ? "0px 2px 4px rgba(0, 0, 0, 0.25)" : "",
          position: isMobile ? (isFloater ? "fixed" : "relative") : "relative",
        }}
      >
        <Items
          style={{
            width: isMobile ? itemWidthMobile * 8 : 1200,
            overflowY: "hidden",
          }}
        >
          <Icon
            slug="todos"
            selected={selected === "todos"}
            setCategory={setCategory}
            text="Todos"
            width={width}
            setSelected={setSelected}
            isMobile={isMobile}
          />
          {categories.map((categoria) => {
            return (
              <Icon
                slug={categoria.icone}
                key={categoria.icone}
                selected={selected === categoria.nome}
                setCategory={setCategory}
                text={categoria.nome}
                width={width}
                setSelected={setSelected}
                isMobile={isMobile}
              />
            );
          })}
        </Items>
      </Scroller>
    </Element>
  );
}
