const API_URL = process.env.REACT_APP_API_URL;

export default function Api() {
  const getMaps = async function () {
    return fetch(API_URL + "/map", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((data) => {
      return data.json();
    });
  };

  const getMap = async function (slug) {
    return fetch(API_URL + `/map/${slug}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((data) => {
      return data.json();
    });
  };

  const getMarkers = async function (file, filter, text) {
    return fetch(API_URL + "/map/" + file, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (data) => {
      // console.log(filter, text);

      if (filter === "todos") {
        return data.json();
      }

      if (filter) {
        let filtered = [];
        data = await data.json();
        // console.log(data);
        for (let index = 0; index < data.locais.length; index++) {
          const marker = data.locais[index];
          // console.log(marker, filter);
          if (marker.categoria.icone === filter) {
            filtered.push(marker);
          }
        }
        data["locais"] = filtered;
        return data;
      }

      if (text) {
        text = text.toLowerCase();
        let filtered = [];
        data = await data.json();
        for (let index = 0; index < data.locais.length; index++) {
          const marker = data.locais[index];
          // console.log(marker);

          const name = marker.nome.toLowerCase();
          const description = marker.descricao
            ? marker.descricao.toLowerCase()
            : "";

          if (name.includes(text) || description.includes(text)) {
            filtered.push(marker);
          }
        }
        data["locais"] = filtered;
        return data;
      }

      return data.json();
    });
  };
  return {
    getMaps,
    getMap,
    getMarkers,
  };
}
