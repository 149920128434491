import React from "react";
import styled from "styled-components";

const Element = styled.div`
  width: 100%;
  text-align: center;
  background: #d7729e center center no-repeat;
  position: relative;
  z-index: 1000000;
`;

const Title = styled.div`
  width: 100%;

  color: #dce554;

  text-align: center;
  font-family: Ohno Blazeface;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 38.4px */
  text-transform: uppercase;
`;

const Subtitle = styled.div`
  margin-top: 16px;

  color: #000;

  text-align: center;
  font-family: Degular;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 28px */
`;

const Button = styled.a`
  display: block;
  margin: 0 auto;
  width: 206px;

  color: #000;

  text-align: center;
  font-family: Degular;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%; /* 20px */
  text-transform: uppercase;
  padding: 16px 0px;
  margin-top: 48px;
  background: #dce554;
  border-radius: 4px;

  &:hover {
    opacity: 0.8;
  }
  &.inverted {
    background: #002ac3;
    color: #fdafc5;
    margin-top: 25px;
  }
`;

export default function Missing({ isMobile }) {
  return (
    <Element
      style={{
        padding: isMobile ? "104px 32px" : "104px 52px",
        backgroundImage: `url(${isMobile ? "" : "/img/missing.png"})`,
      }}
    >
      <Title>Sentiu falta de algum lugar?</Title>
      {isMobile ? (
        <Subtitle style={{ fontSize: isMobile ? "16px" : "20px" }}>
          Tá sabendo de um lugar
          <br /> que não está na nossa
          <br /> lista? Manda pra gente!
        </Subtitle>
      ) : (
        <Subtitle style={{ fontSize: isMobile ? "16px" : "20px" }}>
          Tá sabendo de um lugar que não está na <br />
          nossa lista? Manda pra gente!
        </Subtitle>
      )}
      <Button
        href="https://votelgbt.typeform.com/to/fHaFzplZ"
        target="_blank"
        rel="noreferrer"
      >
        INDIQUE UM LUGAR
      </Button>
    </Element>
  );
}
