import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Api from "../../libs/api";

import Menu from "./menu";

import MapView from "./map_view";

const MapHolder = styled.div`
  width: 100%;
  position: relative;
`;

export default function GoogleMaps({
  width,
  height,
  isMobile,
  isFloater,
  file,
  map,
}) {
  let api = Api();

  const [category, setCategory] = useState(0);
  const [markers, setMarkers] = useState([]);
  const [customCenter, setCustomCenter] = useState(null);

  const getMarkers = async (filter, text) => {
    let response = await api.getMarkers(map.slug, filter, text);
    setMarkers(response.locais);
  };

  const getSelectedItem = (index) => {
    const marker = markers[index];
    if (marker && marker.localizacao && marker.localizacao.length > 0) {
      const location = {
        lat: parseFloat(marker.localizacao.split(",")[0]),
        lng: parseFloat(marker.localizacao.split(",")[1]),
      };
      setCustomCenter(location);
    }
  };

  useEffect(() => {
    setCustomCenter(null);
    getMarkers(category, null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  useEffect(() => {
    getMarkers(null, null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Menu
        setCategory={setCategory}
        isMobile={isMobile}
        isFloater={isFloater}
        width={width}
        categories={map.categorias}
      />
      <MapHolder style={{ height: height - 100 }}>
        <MapView
          width={width}
          height={height}
          isMobile={isMobile}
          isFloater={isFloater}
          markers={markers}
          callback={getSelectedItem}
          customCenter={customCenter}
          getMarkers={getMarkers}
        />
      </MapHolder>
    </>
  );
}
