import React, { useState } from "react";
import styled from "styled-components";

import Helper from "../../libs/helper";

const Element = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
`;

const Item = styled.a`
  width: 12px;
  height: 12px;
  background: #ed1c24;
  border-radius: 100%;
  border: 2px solid #ffffff;
  display: block;
  box-sizing: border-box;

  &:hover {
    border: 4px solid #ffffff;
    width: 16px;
    height: 16px;
  }

  &.active {
    border: 4px solid #ffffff;
    width: 16px;
    height: 16px;
    background: #000000;
  }
`;

export default function Marker({ index, selected, callback, marker }) {
  let helper = Helper();

  const [clicked, setClicked] = useState(false);

  return (
    <Element>
      <Item
        className={selected ? "active" : ""}
        style={{
          zIndex: selected ? 99 : 1,
          background: selected ? "#000000" : helper.getCityColor(marker.city),
        }}
        onClick={() => {
          setClicked(!clicked);
          if (callback) {
            callback(index);
          }
        }}
      />
    </Element>
  );
}
